import { BrowserRouter, Route } from "react-router-dom";
import AboutWAC from "./pages/AboutWAC";
import Home from './components/Home';
import Partners from "./pages/Partners";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationPolicy from "./pages/CancellationPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import AFAbout from "./pages/AFAbout";
import AFAwards from "./pages/AFAwards";
import AFAcademy from "./pages/AFAcademy";
import AngelsOfHumanity from "./pages/AngelsOfHumanity";
import AFMembership from "./pages/AFMembership";
import Contact from "./pages/Contact";
import RegistrationCost from "./pages/RegistrationCost";
import SponsorshipOpportunities from "./pages/SponsorshipOpportunities";
import Registration from "./pages/Registration";
import AwardsOpportunities from "./pages/AwardsOpportunities";
import ExhibitionOpportunities from "./pages/ExhibitionOpportunities";
import AdvertisingOpportunities from "./pages/AdvertisingOpportunities";
import ParticipationCertificate from "./pages/ParticipationCertificate";
import Proceedings from "./pages/Proceedings";
import ThemesAndTopics from './pages/ThemesAndTopics';
import AbstractInformation from "./pages/AbstractInformation";
import Gallery from "./pages/Gallery";
import GalleryView from "./pages/GalleryView";
import IndustryPresentation from "./pages/IndustryPresentation";
import TagManager from 'react-gtm-module';
import QuickRegistration from "./pages/QuickRegistration";
import Clearances from "./pages/Clearances";

const tagManagerArgs = {
  gtmId: 'GTM-5DVP5L3'
}
TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <BrowserRouter>
      <Route exact path="/about-world-aqua-congress" component={AboutWAC} />
      <Route exact path="/about-aqua-foundation" component={AFAbout} />
      <Route exact path="/aqua-foundations-excellence-awards" component={AFAwards} />
      <Route exact path="/aqua-foundation-academy" component={AFAcademy} />
      <Route exact path="/angels-of-humanity" component={AngelsOfHumanity} />
      <Route exact path="/memberships" component={AFMembership} />
      <Route exact path="/partners" component={Partners} />
      <Route exact path="/contact-us" component={Contact} />
      <Route exact path="/quick-registration" component={QuickRegistration} />
      <Route exact path="/clearances" component={Clearances} />
      <Route exact path="/registration-cost" component={RegistrationCost} />
      <Route exact path="/register-as-delegate" component={Registration} />
      <Route exact path="/sponsorship-opportunities" component={SponsorshipOpportunities} />
      <Route exact path="/awards-opportunities" component={AwardsOpportunities} />
      <Route exact path="/exhibition-opportunities" component={ExhibitionOpportunities} />
      <Route exact path="/industry-presentation" component={IndustryPresentation} />
      <Route exact path="/advertising-opportunities" component={AdvertisingOpportunities} />
      <Route exact path="/participation-certificate" component={ParticipationCertificate} />
      <Route exact path="/proceedings" component={Proceedings} />
      <Route exact path="/conference-themes-and-topics" component={ThemesAndTopics} />
      <Route exact path="/conference-abstract-information" component={AbstractInformation} />
      <Route exact path="/10-wac-gallery" render={(props) => <Gallery gallery={10} {...props} />} />
      <Route exact path="/xi-wac-gallery" render={(props) => <Gallery gallery={11} {...props} />} />
      <Route exact path="/xii-wac-gallery" render={(props) => <Gallery gallery={12} {...props} />} />
      <Route exact path="/xiii-wac-gallery" render={(props) => <Gallery gallery={13} {...props} />} />
      <Route exact path="/gallery/:galleryId" component={GalleryView} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/cancellation-and-refund-policy" component={CancellationPolicy} />
      <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
      <Route exact path="/" component={Home} />
    </BrowserRouter>
    
  );
}

export default App;
