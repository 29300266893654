import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";

function Clearances() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Clearances</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <div className="row mb-5">
                <div className="col-md-4 col-12">
                  <div className="border p-0">
                    <a className="p-0 text-center" target="_blank" rel="noreferrer" href="/assets/clearances/mha-clearance.pdf">
                      <img alt="" className="my-2 mx-auto d-block" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                      <div className=" text-white bg-primary w-100">
                        <p className="p-1 text-white bg-primary">Ministry of Home Affairs<br /> Government of India</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="border p-0">
                    <a className="p-0 text-center" target="_blank" rel="noreferrer" href="/assets/clearances/mea-clearance.pdf">
                      <img alt="" className="my-2 mx-auto d-block" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                      <div className="text-white bg-primary w-100">
                        <p className="p-1 text-white bg-primary">Ministry of External Affairs<br />Government of India</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <br />
              <ContactDetails />
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default Clearances;
